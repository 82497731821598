// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-project-beme-js": () => import("./../../../src/pages/project-beme.js" /* webpackChunkName: "component---src-pages-project-beme-js" */),
  "component---src-pages-project-dat-ds-js": () => import("./../../../src/pages/project-dat-ds.js" /* webpackChunkName: "component---src-pages-project-dat-ds-js" */),
  "component---src-pages-project-dat-one-js": () => import("./../../../src/pages/project-dat-one.js" /* webpackChunkName: "component---src-pages-project-dat-one-js" */),
  "component---src-pages-project-scroll-js": () => import("./../../../src/pages/project-scroll.js" /* webpackChunkName: "component---src-pages-project-scroll-js" */),
  "component---src-pages-project-sd-wan-js": () => import("./../../../src/pages/project-sd-wan.js" /* webpackChunkName: "component---src-pages-project-sd-wan-js" */),
  "component---src-pages-project-simplifi-js": () => import("./../../../src/pages/project-simplifi.js" /* webpackChunkName: "component---src-pages-project-simplifi-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

